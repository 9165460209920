import { DigiEyeCore } from '@/config/http/axios.core-config.ts';
import { API_ROUTES_UPDATED } from '@/constants/API_ROUTES.ts';
import {
  TCustomFooterForm,
  TCustomHeaderForm,
  TInvoiceForm,
  TInvoiceResponse,
  TInvoiceSummeryResponse,
  TInvoiceTypeDetailsResponse,
  TLineItem,
} from '@invoices/util/types';

export const getInvoicesSummery = async ({ uniqueCode }: { uniqueCode: string }): Promise<TInvoiceSummeryResponse> => {
  return await DigiEyeCore.get(`${API_ROUTES_UPDATED.GET_INSPECTION_INVOICES_SUMMERY}`, {
    params: { inspectionId: uniqueCode },
  })
    .then((res) => {
      return { invoices: res.data?.data || [], updatedAt: new Date().toISOString() };
    })
    .catch(() => {
      return { invoices: [], updatedAt: undefined };
    });
};

export const getInvoices = async ({ uniqueCode }: { uniqueCode: string }): Promise<TInvoiceResponse[] | undefined> => {
  return await DigiEyeCore.get(API_ROUTES_UPDATED.INSPECTION_INVOICES, {
    params: { inspectionId: uniqueCode, version: 'latest' },
  }).then((res) => {
    if (res === undefined || !res.data) return [];

    //TODO: Remove the map- Ex: in getInvoicesSummery
    return res.data.data.map(
      (invoice: {
        invoiceTypeCode: string;
        invoiceId: number;
        invoiceName: string;
        invoiceNumber: string;
        referenceNumber: string;
        inspectionId: string;
        vehicleNumber: string;
        lineItems: TLineItem[];
      }) =>
        ({
          invoiceTypeCode: invoice.invoiceTypeCode,
          invoiceId: invoice.invoiceId,
          invoiceName: invoice.invoiceName,
          invoiceNumber: invoice.invoiceNumber,
          referenceNumber: invoice.referenceNumber,
          inspectionId: invoice.inspectionId,
          vehicleNumber: invoice.vehicleNumber,
          lineItems: invoice.lineItems,
        }) as TInvoiceResponse
    );
  });
};

export const getInvoice = async ({
  invoiceId,
}: {
  invoiceId: number | undefined;
}): Promise<TInvoiceResponse | undefined> => {
  if (!invoiceId) return;

  const { data } = await DigiEyeCore.get(`${API_ROUTES_UPDATED.INSPECTION_INVOICES}/${invoiceId}`);
  if (!data.success) throw new Error(data.message || 'Error fetching invoice');
  return data.data as TInvoiceResponse;
};

export const generateInvoicePDF = async ({
  referenceNumber,
  token,
  inspectionId,
  vehicleNo,
  reportType,
  reportName,
  includeSpecifiedFields = true,
  includeEvidence = true,
  includeTaxInvoice = true,
}: {
  referenceNumber?: string;
  token?: string;
  inspectionId?: string;
  vehicleNo?: string;
  reportType: string;
  reportName?: string;
  includeSpecifiedFields?: boolean;
  includeEvidence?: boolean;
  includeTaxInvoice?: boolean;
}): Promise<void> => {
  if (!referenceNumber) return;

  const response = await DigiEyeCore.get(
    `${API_ROUTES_UPDATED.GENERATE_INSPECTION_PDF}${referenceNumber}&inspectionId=${inspectionId}&reportType=${reportType}&includeSpecifiedFields=${includeSpecifiedFields}&includeEvidence=${includeEvidence}&includeTaxInvoice=${includeTaxInvoice}`,
    {
      responseType: 'arraybuffer',
      headers: {
        authToken: `${token}`,
      },
    }
  );

  const blob = new Blob([response.data], { type: 'application/pdf' });

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);

  // switch (reportType) {
  //   case 'inspectionReport':
  //     link.download = `Inspection Report ${vehicleNo} ${Date.now()}.pdf`;
  //     break;
  //   case 'surveyReport':
  //     link.download = `Survey Report ${vehicleNo} ${Date.now()}.pdf`;
  //     break;
  //   case 'reInspectionReport':
  //     link.download = `Re-Inspection Report ${vehicleNo} ${Date.now()}.pdf`;
  //     break;
  //   case 'immediateAdviceReport':
  //     link.download = `Immediate Advice Report ${vehicleNo} ${Date.now()}.pdf`;
  //     break;
  //   default:
  //     throw new Error('Invalid report type specified.');
  // }

  link.download = `${reportName || ''} Report ${vehicleNo} ${Date.now()}.pdf`;
  link.click();

  window.URL.revokeObjectURL(link.href);
};
//TODO: Remove the below code for testing preview generation
// export const getInvoicePDFUrl = async ({
//   referenceNumber,
//   token,
//   inspectionId,
//   vehicleNo,
// }: {
//   referenceNumber?: string;
//   token?: string;
//   inspectionId?: string;
//   vehicleNo?: string;
// }): Promise<string | null> => {
//   if (!referenceNumber) return null;

//   try {
//     const response = await DigiEyeCore.get(
//       `${API_ROUTES_UPDATED.GENERATE_INSPECTION_PDF}${referenceNumber}&inspectionId=${inspectionId}`,
//       {
//         responseType: 'arraybuffer',
//         headers: {
//           authToken: `${token}`,
//         },
//       }
//     );

//     const blob = new Blob([response.data], { type: 'application/pdf' });
//     const pdfUrl = window.URL.createObjectURL(blob);

//     return pdfUrl;
//   } catch (error) {
//     console.error(`Failed to generate PDF URL: ${vehicleNo}`, error);
//     return null;
//   }
// };

export const createInvoice = async (
  invoice: Pick<
    TInvoiceResponse,
    'invoiceNumber' | 'invoiceTypeCode' | 'invoiceName' | 'inspectionId' | 'referenceNumber' | 'vehicleNumber'
  >
) => {
  return await DigiEyeCore.post(API_ROUTES_UPDATED.INSPECTION_INVOICES, invoice).then((res) => {
    return { success: res?.data?.data.success, message: res?.data?.data.message };
  });
};

export const deleteLineItem = async (invoiceId: number, lineItemId: number) => {
  if (!invoiceId || !lineItemId) return { success: false, message: 'Invalid invoice id or lineItem id' };

  return await DigiEyeCore.delete(
    `${API_ROUTES_UPDATED.INSPECTION_INVOICES}/${invoiceId}/lineItems/${lineItemId}`
  ).then((res) => {
    return {
      success: res?.status === 204,
    };
  });
};

export const createLineItem = async (invoiceId: number | undefined, lineItem: TInvoiceForm) => {
  if (!invoiceId || !lineItem) return { success: false, message: 'Invalid invoice id or line item' };

  return await DigiEyeCore.post(`${API_ROUTES_UPDATED.INSPECTION_INVOICES}/${invoiceId}/lineItems`, lineItem).then(
    (res) => {
      return { success: res?.data?.data.success, message: res?.data?.data.message };
    }
  );
};

export const updateLineItem = async (
  invoiceId: number | undefined,
  lineItem: TInvoiceForm,
  lineItemId: number | undefined
) => {
  if (!invoiceId || !lineItem || !lineItemId)
    return { success: false, message: 'Invalid invoice id, line item or line item id' };

  return await DigiEyeCore.put(
    `${API_ROUTES_UPDATED.INSPECTION_INVOICES}/${invoiceId}/lineItems/${lineItemId}`,
    lineItem
  ).then((res) => {
    return { success: res?.data?.data.success, message: res?.data?.data.message };
  });
};

export const updateInvoice = async (
  invoiceId: number | undefined,
  invoice: TInvoiceResponse | TCustomFooterForm | TCustomHeaderForm
) => {
  if (!invoiceId || !invoice) return { success: false, message: 'Invalid invoice id or invoice' };

  return await DigiEyeCore.put(`${API_ROUTES_UPDATED.INSPECTION_INVOICES}/${invoiceId}`, invoice).then((res) => {
    return { success: res?.data?.data.success, message: res?.data?.data.message };
  });
};

export const getInvoiceTypes = async (): Promise<any[]> => {
  return await DigiEyeCore.get(
    `${API_ROUTES_UPDATED.INVOICE_TYPE_DETAILS}?projection=invoiceTypeCode%2CinvoiceTypeName`
  ).then((res) => {
    return res.data.data;
  });
};

export const getInvoiceTypeDetails = async ({
  invoiceTypeCode,
}: {
  invoiceTypeCode: string | undefined;
}): Promise<TInvoiceTypeDetailsResponse | undefined> => {
  if (!invoiceTypeCode) return;

  const { data } = await DigiEyeCore.get(`${API_ROUTES_UPDATED.INVOICE_TYPE_DETAILS}/${invoiceTypeCode}`);
  if (!data.success) throw new Error(data.message || 'Error fetching invoice type details');
  return data.data as TInvoiceTypeDetailsResponse;
};

export const updateOverallDiscount = async (invoiceId: number, discount: number) => {
  if (!invoiceId || !discount) return { success: false, message: 'Invalid invoice id or discount' };
  return await DigiEyeCore.put(`${API_ROUTES_UPDATED.INSPECTION_INVOICES}/${invoiceId}/lineItems`, { discount }).then(
    (res) => {
      return { success: res?.data?.success, message: res?.data?.message };
    }
  );
};

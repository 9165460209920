import ContentOverlay from '@/components/ContentOverlay';
import { useAuth } from '@/config/AuthContext/useAuth';
import IncidentDetailsForm from '@/features/inspection/components/IncidentDetails/IncidentDetailsForm';
import { TReportTemplate } from '@/features/inspection/types';
import { generateInvoicePDF } from '@/features/invoices/services/invoices.service';
import { useToastApi } from '@/hooks/useToastApi';
import { ContainerOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Flex, Row } from 'antd';
import Text from 'antd/es/typography/Text';
import { CSSProperties, useState } from 'react';
import { getColorBrightness } from '../../util/styling';

type EditAndDownloadReportProps = {
  color?: string;
  referenceNumber: string | undefined;
  inspectionId: string | undefined;
  vehicleNo: string | undefined;
};

const EditAndDownloadReport = (props: EditAndDownloadReportProps & TReportTemplate) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [includeSpecifiedFields, setIncludeSpecifiedFields] = useState<boolean>(true);
  const [includeEvidence, setIncludeEvidence] = useState<boolean>(true);
  const [includeTaxInvoice, setIncludeTaxInvoice] = useState<boolean>(true);

  const reportsWithSpecifiedFields = ['inspection', 're-inspection'];
  const reportsWithEvidence = ['pre-survey'];
  const reportsWithTaxInvoice = ['inspection'];

  const styles = useStyles();
  const {
    authState: { token },
  } = useAuth();
  const toast = useToastApi();

  const pdfGeneration = async () => {
    setLoading(true);
    toast.info({
      key: 'pdfGeneration',
      content: 'Download will begin shortly!',
      duration: 0,
    });
    await generateInvoicePDF({
      referenceNumber: props.referenceNumber,
      token,
      inspectionId: props.inspectionId,
      vehicleNo: props.vehicleNo,
      reportName: props.templateLabel,
      reportType: props?.templateName,
      includeSpecifiedFields,
      includeEvidence,
      includeTaxInvoice,
    })
      .then(() => {
        toast.success({
          key: 'pdfGeneration',
          content: 'Your report has been generated!',
        });
        setIsModalVisible(false);
      })
      .catch(() => {
        toast.error({
          key: 'pdfGeneration',
          content: 'An error occurred while generating the report!',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button size="small" color="primary" onClick={() => setIsModalVisible(true)}>
        Edit & Download
      </Button>
      <ContentOverlay
        className="scrollContainer"
        style={styles.modalContent}
        title={
          <Row gutter={16} align={'middle'}>
            <Col>
              <ContainerOutlined style={styles.icon(props?.color || '#F6F7F9')} />
            </Col>
            <Col style={styles.descriptionContainer}>
              <Flex vertical>
                <Text>{props?.templateLabel}</Text>
                <Text style={styles.description}>{props?.description}</Text>
              </Flex>
            </Col>
          </Row>
        }
        footer={
          <Flex vertical justify="space-between" gap={8}>
            {reportsWithSpecifiedFields.includes(props?.templateName) && (
              <Checkbox
                children={'Include Sum Insured, Market Value, and Excess in the report.'}
                checked={includeSpecifiedFields}
                onChange={(e) => setIncludeSpecifiedFields(e.target.checked)}
                style={styles.checkboxLabel}
              />
            )}
            {reportsWithEvidence.includes(props?.templateName) && (
              <Checkbox
                children={'Include evidences in the report.'}
                checked={includeEvidence}
                onChange={(e) => setIncludeEvidence(e.target.checked)}
                style={styles.checkboxLabel}
              />
            )}
            {reportsWithTaxInvoice.includes(props?.templateName) && (
              <Checkbox
                children={
                  <p>
                    Include <strong>Tax Invoice (Inspection)</strong> in the report.
                  </p>
                }
                checked={includeTaxInvoice}
                onChange={(e) => setIncludeTaxInvoice(e.target.checked)}
                style={styles.checkboxLabel}
              />
            )}

            <Button type="primary" onClick={() => pdfGeneration()} loading={loading}>
              Download Report
            </Button>
          </Flex>
        }
        width={'35%'}
        closable={!loading}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <IncidentDetailsForm
          templateName={props.templateName || ''}
          referenceNumber={props.referenceNumber || ''}
          view="MODAL"
        />
      </ContentOverlay>
    </>
  );
};

const useStyles = () => {
  return {
    icon: (color: string) => {
      const brightness = getColorBrightness(color);
      const iconColor = brightness > 150 ? 'black' : 'white';

      return {
        justifyContent: 'center',
        backgroundColor: color,
        borderRadius: 8,
        color: iconColor,
        height: 35,
        width: 35,
      } as CSSProperties;
    },
    descriptionContainer: {
      alignContent: 'center',
    } as CSSProperties,
    description: {
      fontWeight: 400,
      fontSize: 12,
      color: 'black',
      opacity: 0.6,
    } as CSSProperties,
    modalContent: {
      padding: '4px 8px 0 0',
      overflowY: 'auto',
      overflowX: 'hidden',
      scrollbarWidth: 'thin',
    } as CSSProperties,
    checkboxLabel: {
      fontSize: 12,
    },
  };
};

export default EditAndDownloadReport;

import CurrencySelectInput from '@/components/CurrencySelectInput';
import TelephoneSelectInput from '@/components/TelephoneSelectInput';
import { useAuth } from '@/config/AuthContext/useAuth.ts';
import env from '@/config/env.ts';
import { queryClient } from '@/config/http/react-query.config.ts';
import { getInspectionRoute } from '@/constants/API_ROUTES.ts';
import { TCreateInspection, TLabelValuePair } from '@/features/inspectionList/types';
import { getCurrency } from '@/features/invoices/util';
import useCopyLink from '@/hooks/useCopyLink';
import { useToastApi } from '@/hooks/useToastApi';
import { CarOutlined, MobileOutlined, SafetyCertificateOutlined, SaveFilled } from '@ant-design/icons';
import { TErrorResponse } from '@invoices/util/types.tsx';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Col, Flex, Form, Input, Row, Select, Space, Switch, TreeSelect, Typography } from 'antd';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { CSSProperties, useEffect, useState } from 'react';
import { createInspection, fetchEventData, fetchRolesList } from '../services/inspectionList.service';
import { filterInspectionTypes } from '../utils/filterInspecitonTypes.util';
import { getTreeDataParent } from '../utils/getTreeDataParent.util';

// TODO: Add this to ENV
const ASSESSOR_ONLY_TENANTS = ['ajax'];
const INTERNATIONAL_TENANTS = ['ajax'];

type Props = {
  inspectionTypes: [];
  fetchInspectionTypesLoading: boolean;
  assessors: any[] | undefined;
  fetchAssessorsLoading: boolean;
  setOpen: (isClose: boolean) => void;
  isClaimCreated: (isClaimCreated: boolean) => void;
};

const InspectionCreateForm = ({
  inspectionTypes = [],
  fetchInspectionTypesLoading = false,
  assessors = [],
  fetchAssessorsLoading = false,
  setOpen = (isOpen) => {
    return isOpen;
  },
  isClaimCreated = (isClaimCreated) => {
    return isClaimCreated;
  },
}: Props) => {
  const styles = useStyles();
  const columnSpan = 8;

  const [inspectionType, setInspectionType] = useState<string>();
  const [inspectionTypeSection, setInspectionTypeSection] = useState<string>();
  const [inspectionTypeClass, setInspectionTypeClass] = useState<string>();
  const [isExistingEvent, setIsExistingEvent] = useState<boolean>(false);
  const [isSendAssessor, setIsSendAssessor] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [appUrl, setAppUrl] = useState<URL>();
  const [generalUrl, setGeneralUrl] = useState<URL>();
  const [customerUrl, setCustomerUrl] = useState<URL>();
  const [referenceNumber, setReferenceNumber] = useState<string>();
  const [eventData, setEventData] = useState<{ cls: string; section: string; referenceNumber: string }>();

  const [form] = Form.useForm();
  const toastApi = useToastApi();
  const { copyLink } = useCopyLink();
  const { authState } = useAuth();
  const CURRENCY = getCurrency(authState.tenantId);
  useEffect(() => {
    if (ASSESSOR_ONLY_TENANTS.includes(authState.tenantId)) setIsSendAssessor(true);
  }, [authState]);

  const { data: roles, isLoading: fetchRolesListLoading } = useQuery({
    queryKey: ['roles-list'],
    queryFn: () => fetchRolesList(),
  });

  const notifyRoles = roles
    ? roles.filter((role: TLabelValuePair) => ['operator', 'engineer'].includes(role.value))
    : [];

  const mutation = useMutation({
    mutationFn: ({ form: submittedValues }: TCreateInspection) => createInspection({ form: submittedValues }),
    onSuccess: (data) => {
      form.resetFields();
      setGeneralUrl(data?.data.link.generalUrl);
      setAppUrl(data?.data.link.appUrl);
      setCustomerUrl(data?.data.link.customerLinkUrl);
      setIsSubmitted(data?.success);
      isClaimCreated(data?.success);
      toastApi.open({
        type: data?.success ? 'success' : 'error',
        content: data?.success ? 'Inspection Created successfully!' : data.message,
        duration: 2,
      });

      queryClient.invalidateQueries({ queryKey: ['inspection-list'] });

      // following code block is implemented as there is a delay in the db syncing process
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['inspection-list'] });
      }, 2000);
    },
    onError: (error: TErrorResponse) => {
      form.resetFields();
      setGeneralUrl(undefined);
      setAppUrl(undefined);
      setCustomerUrl(undefined);
      const errorMessage = error?.response?.data?.message || 'Something went wrong';
      toastApi.open({
        type: 'error',
        content: errorMessage,
        duration: 2,
        style: { zIndex: '3000 !important' },
      });
    },
  });

  const fetchEventDataMutation = useMutation({
    mutationFn: (referenceNumber: string) => fetchEventData(referenceNumber),
    onSuccess: (data) => {
      setEventData(data.data);
      toastApi.open({
        type: data?.success ? 'success' : 'error',
        content: data?.success ? data.message || 'Event data retrieved successfully!' : data.message,
        duration: 2,
      });
    },
    onError: (error) => {
      setEventData(undefined);
      if (error instanceof Error)
        toastApi.open({
          type: 'error',
          content: error?.message || 'Something went wrong!',
          duration: 2,
        });
    },
  });

  const onFinish = (values: any) => {
    const submittedValues = {
      assessor: values.assessor,
      contactNumber: values.contactNumber,
      customerName: values.customerName,
      eventReferenceNumber: values.eventReferenceNumber,
      inspectionType: values.inspectionType,
      notifyRole: values.notifyRole,
      policyNumber: values.policyNumber,
      sumAssured: values.sumAssured && values.sumAssured.toString(),
      vehicleNumber: values.vehicleNumber,
      isExistingEvent: isExistingEvent,
      isSendAssessor: isSendAssessor,
    };

    mutation.mutate({ form: submittedValues });
  };

  useEffect(() => {
    setInspectionTypeSection(getTreeDataParent(inspectionType ? inspectionType : '', inspectionTypes));
    setInspectionTypeClass(getTreeDataParent(inspectionTypeSection ? inspectionTypeSection : '', inspectionTypes));
    return () => {
      setInspectionType(undefined);
      setInspectionTypeSection(undefined);
      setInspectionTypeClass(undefined);
    };
  }, [inspectionType, inspectionTypeSection, inspectionTypeClass, inspectionTypes]);

  return (
    <div style={styles.container}>
      {isSubmitted ? (
        <Flex vertical>
          <Typography.Title level={4} style={{ margin: 0 }}>
            Inspection Created Successfully
          </Typography.Title>
          <Typography.Text type="secondary">
            You can share following links to collect evidence (Click to copy)
          </Typography.Text>

          <Flex gap={50} style={styles.buttonGroupContainer} align="start">
            {generalUrl && (
              <Flex vertical justify="center" align="center" style={styles.buttonContainer}>
                <Button
                  style={styles.copyLinkButton}
                  type="default"
                  icon={<SafetyCertificateOutlined style={styles.buttonIcon} />}
                  onClick={() => {
                    if (generalUrl) copyLink(generalUrl, 'General Inspection Portal');
                  }}
                ></Button>
                <Typography.Text type="secondary" style={styles.buttonText}>
                  General Inspection Portal
                </Typography.Text>
              </Flex>
            )}
            {customerUrl && (
              <Flex vertical justify="center" align="center" style={styles.buttonContainer}>
                <Button
                  style={styles.copyLinkButton}
                  type="default"
                  icon={<CarOutlined style={styles.buttonIcon} />}
                  onClick={() => {
                    if (customerUrl) copyLink(customerUrl, 'Motor Inspection Portal');
                  }}
                ></Button>
                <Typography.Text type="secondary" style={styles.buttonText}>
                  Motor Inspection Portal
                </Typography.Text>
              </Flex>
            )}
            {appUrl && (
              <Flex vertical justify="center" align="center" style={styles.buttonContainer}>
                <Button
                  style={styles.copyLinkButton}
                  type="default"
                  icon={<MobileOutlined style={styles.buttonIcon} />}
                  onClick={() => {
                    if (appUrl) copyLink(appUrl, 'Mobile App Portal');
                  }}
                ></Button>
                <Typography.Text type="secondary" style={styles.buttonText}>
                  Mobile App Portal
                </Typography.Text>
              </Flex>
            )}
          </Flex>
          {mutation?.data?.data?.hash && (
            <Flex align={'center'} justify={'end'}>
              <a href={getInspectionRoute(mutation?.data?.data?.hash)}>
                <Button type={'primary'}>View and collect incident details</Button>
              </a>
            </Flex>
          )}
        </Flex>
      ) : (
        <>
          <Row style={{ marginBottom: 24, marginTop: 24 }}>
            <Col span={24}>
              <Flex justify="end" gap={24}>
                <Space>
                  <Switch id="existingEventBtn" onChange={setIsExistingEvent} />
                  <Typography.Link>
                    <label htmlFor="existingEventBtn">Existing Event</label>
                  </Typography.Link>
                </Space>
                <Space>
                  <Switch
                    id="sendAssessorBtn"
                    {...(ASSESSOR_ONLY_TENANTS.includes(authState.tenantId) ? { disabled: true, checked: true } : {})}
                    onChange={setIsSendAssessor}
                  />
                  <Typography.Link>
                    <label htmlFor="sendAssessorBtn">
                      {!env.VITE_NODE_ENV.includes('prod') || authState.tenantId === 'ajax'
                        ? 'Send Surveyor'
                        : 'Send Assessor'}
                    </label>
                  </Typography.Link>
                </Space>
              </Flex>
            </Col>
          </Row>
          <Form layout="vertical" onFinish={onFinish}>
            <Row gutter={16}>
              <Col span={columnSpan}>
                <Form.Item
                  label={'Event Reference Number'}
                  name={'eventReferenceNumber'}
                  rules={[
                    { required: isExistingEvent },
                    {
                      pattern: new RegExp('^[a-zA-Z0-9]*$'),
                      message: 'Invalid format for Reference Number',
                    },
                  ]}
                >
                  <Input
                    placeholder="Event Reference Number"
                    onChange={(e) => setReferenceNumber(e.target.value)}
                    onBlur={() => {
                      isExistingEvent && fetchEventDataMutation.mutate(referenceNumber as string);
                    }}
                  />
                </Form.Item>
              </Col>
              {!isExistingEvent ? (
                <>
                  <Col span={columnSpan}>
                    <Form.Item
                      label={'Contact Number'}
                      name={'contactNumber'}
                      rules={[
                        { required: true },
                        {
                          validator: async (_, value) => {
                            if (!value) {
                              return Promise.reject('Contact Number is required');
                            }

                            if (!isValidPhoneNumber('+' + value)) {
                              return Promise.reject('Contact Number is invalid');
                            }

                            return Promise.resolve();
                          },
                          message: 'Contact Number is invalid',
                        },
                      ]}
                    >
                      <TelephoneSelectInput
                        placeholder="Contact Number"
                        defaultCountry={INTERNATIONAL_TENANTS.includes(authState.tenantId) ? undefined : 'LK'}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={columnSpan}>
                    <Form.Item
                      label={'Customer Name'}
                      name={'customerName'}
                      rules={[
                        { required: true },
                        {
                          pattern: new RegExp('^[a-z A-Z]*$'),
                          message: 'Invalid format for Customer Name',
                        },
                      ]}
                    >
                      <Input placeholder="Customer Name" />
                    </Form.Item>
                  </Col>{' '}
                </>
              ) : null}
              <Col span={columnSpan}>
                <Form.Item label={'Inspection Type'} name={'inspectionType'} rules={[{ required: true }]}>
                  <TreeSelect
                    placeholder="Select Type"
                    treeData={
                      isExistingEvent ? filterInspectionTypes(inspectionTypes, eventData) : inspectionTypes || []
                    }
                    loading={fetchInspectionTypesLoading}
                    onChange={setInspectionType}
                  />
                </Form.Item>
              </Col>
              {!isExistingEvent ? (
                <>
                  <Col span={columnSpan}>
                    <Form.Item label={'Vehicle Number'} name={'vehicleNumber'} rules={[{ required: true }]}>
                      <Input placeholder="Vehicle Number or NM" />
                    </Form.Item>
                  </Col>
                  <Col span={columnSpan}>
                    <Form.Item
                      label={'Policy Number'}
                      name={'policyNumber'}
                      rules={[
                        { required: true },
                        {
                          pattern: new RegExp('^[a-zA-Z0-9]*$'),
                          message: 'Invalid format for Policy Number',
                        },
                      ]}
                    >
                      <Input placeholder="Policy Number" />
                    </Form.Item>
                  </Col>
                  <Col span={columnSpan}>
                    <Form.Item
                      label={'Sum Assured'}
                      name={'sumAssured'}
                      rules={[{ required: true }, { type: 'number' }]}
                    >
                      <CurrencySelectInput placeholder="Sum Assured" defaultCurrency={CURRENCY} />
                    </Form.Item>
                  </Col>
                </>
              ) : null}
              <Col span={columnSpan}>
                <Form.Item label={'Notify'} name={'notifyRole'} rules={[{ required: true }]}>
                  <Select
                    loading={fetchRolesListLoading}
                    options={notifyRoles || []}
                    placeholder="Select"
                    allowClear
                    showSearch
                  />
                </Form.Item>
              </Col>
              {isSendAssessor ? (
                <Col span={columnSpan}>
                  <Form.Item
                    label={
                      !env.VITE_NODE_ENV.includes('prod') || authState.tenantId === 'ajax' ? 'Surveyor' : 'Assessor'
                    }
                    name={'assessor'}
                    rules={[{ required: true }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      placeholder={
                        !env.VITE_NODE_ENV.includes('prod') || authState.tenantId === 'ajax' ? 'Surveyor' : 'Assessor'
                      }
                      options={assessors.filter((assessor) => assessor.value !== 'customerLink') || []}
                      loading={fetchAssessorsLoading}
                    />
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
            <Row style={{ marginTop: 24 }}>
              <Col span={24}>
                <Flex gap={8} justify="end">
                  <Space>
                    <Button type="default" htmlType="reset" onClick={() => setOpen(false)}>
                      Cancel
                    </Button>
                  </Space>
                  <Space>
                    <Button type="primary" htmlType="submit" icon={<SaveFilled />} loading={mutation.isPending}>
                      {authState.tenantId === 'ajax' ? 'Create Inspection' : 'Create & Send SMS'}
                    </Button>
                  </Space>
                </Flex>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </div>
  );
};

export default InspectionCreateForm;

const useStyles = () => {
  return {
    col: {
      width: '100%',
    } as CSSProperties,
    container: {
      margin: 16,
    } as CSSProperties,
    field: {
      width: '100%',
    } as CSSProperties,
    copyLinkButton: {
      width: '100px',
      height: '100px',
    } as CSSProperties,
    buttonContainer: {
      width: 'fit-content',
    } as CSSProperties,
    buttonGroupContainer: {
      alignSelf: 'center',
      marginTop: '40px',
      marginBottom: '10px',
    } as CSSProperties,
    buttonIcon: {
      fontSize: '50px',
    } as CSSProperties,
    buttonText: {
      textAlign: 'center',
    } as CSSProperties,
  };
};
